.banner {
  padding: 80px;
  justify-content: space-between;
  align-items: baseline;

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    width: 60%;
  }

  &__hero {
    // width: 50%;
    // height: 278px;
    flex-shrink: 0;
    display: flex;
    // height: 369px;
    // padding: 45px 0px 46px 0px;
    justify-content: center;
    align-items: center;
  }
}