.modal {
  h2 {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;

    @include respond(phone) {
      font-size: 1rem;
    }
  }
}
