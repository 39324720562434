.users {
  width: 100%;
  flex: 1;
  position: relative;

  &__filters {
    width: 100%;
    display: flex;
    gap: 1.5rem;
    margin: 2rem 0 1.5rem 0;

    &__input {
      width: 18rem;
    }
  }
}
