$THEME_COLOR: #12083a;
$PRIMARY_COLOR: #090221;
$SECONDARY_COLOR: #0f76e3;
$BG_COLOR: #f3f4f5;

$GRAY-2: #edf2f6;

$TEXT_WHITE: #fff;
$TEXT_DEFAULT_COLOR: #32395f;
$TEXT_GRAYISH_COLOR: #96a5b8;

$DANGER: #e03636;
$PRIMARY: #12083a;
$SKY: #00b0f0;

$HOME_COLOR: #0A0D23;
$HOME_PRIMARY: #00B0F0;
$HOME_GRAYISH: #737794;

