.passwordRecovery {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__navigation {
    width: 2.25rem;
    height: 2.25rem;
    background-color: $THEME_COLOR;
    border-radius: 50%;
    position: absolute;
    top: 2rem;
    left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__box {
    max-width: 25rem;
    width: 100%;
    text-align: center;
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    h4 {
      font-weight: 600;
      margin-bottom: 0.75rem;
      font-size: 1.25rem;
    }

    h3 {
      font-weight: 600;
      margin-bottom: 1.75rem;
      font-size: 1.25rem;
    }

    p {
      font-size: 0.85rem;
      color: $TEXT_GRAYISH_COLOR;
      line-height: 1.25rem;
      margin-bottom: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.025rem;
    }

    &__form {
      .cInput {
        &:not(:first-child) {
          margin-top: 1.25rem;
        }
      }
      button {
        margin-top: 2.25rem;
      }

      &__code {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 0.35rem;
        input {
          box-shadow: 0px 4px 40px 0px #0000001a;
          width: 5rem;
          height: 5rem;
          border: none;
          outline: none;
          border-radius: 0.75rem;
          text-align: center;
          font-size: 2rem;

          border: 1px solid #edf2f6;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }
  }
}
