@media (min-width: 900px) {
  .sideBarhome {
    * {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .sideBarHome {
    display: flex;
    height: 100%;
    width: 260px;
    padding-right: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &__leftContainer {
      background-color: #0a0d23;
      display: flex;
      width: 256px;
      height: 100vh;
      padding-bottom: 30px;
      flex-direction: column;
      justify-content: space-between;
      flex-shrink: 0;

      &__tabs {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 42px;
        align-self: stretch;

        &__logo {
          cursor: pointer;
          display: flex;
          height: 127px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-self: stretch;
        }

        &__links {
          display: flex;
          padding: 0px 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;

          &__button {
            display: flex;
            height: 61px;
            padding: 12px 0px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            cursor: pointer;

            div {
              color: #fff;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              /* 171.429% */
              letter-spacing: 0.5px;
            }
          }
        }
      }

      &__buttons {
        padding: 0 16px;
      }
    }
  }
}