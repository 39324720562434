.mainLayout {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow-x: hidden;
  position: relative;

  @include respond(tab-port) {
    flex-flow: column;
  }

  &__mobileSidebar {
    display: none;
    width: 100%;
    height: 3rem;
    padding: 0.5rem 1rem;

    align-items: center;
    gap: 0.75rem;

    @include respond(tab-port) {
      display: flex;
    }
  }

  &__sidebar {
    width: 17.5rem !important;
    display: flex;
    position: fixed;
    z-index: 55555555555 !important;

    @include respond(tab-port) {
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      z-index: 100;
      width: 100% !important;
    }

    &__overlay {
      display: none;

      @include respond(tab-port) {
        display: flex;
        flex: 1;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__container {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__header {
      @include mainHeading;
    }

    &__icons {
      display: flex;
    }

    &__icons>img {
      margin-left: 24px;
    }

    &__icons>button {
      margin-left: 8px;
    }
  }

  &__header {
    position: absolute;
    right: 2rem;
    top: 2.25rem;
    display: flex;
    z-index: 100;

    button {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    @include respond(tab-port) {
      top: 0.75rem;
    }
  }

  &__page {
    flex: 1;
    display: flex;
    flex-flow: column;
    padding: 2.25rem 2rem;
    position: absolute;
    left: 17.5rem;
    width: calc(100% - 17.5rem);

    @include respond(tab-land) {
      padding: 1.75rem 1.5rem;
    }

    @include respond(phone) {
      padding: 1.25rem 1rem;
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 0.85rem;
      align-items: center;
      height: 5rem !important;

      span {
        color: #96a5b8;
        display: inline-block;
      }
    }
  }
}