@media (min-width: 900px) {
  .cookiesMob {
    * {
      display: none;
    }
  }

  .cookies {
    display: flex;
    padding: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    // border: 1px solid white;
    &__heading {
      color: #fff;
      text-align: center;
      font-size: 56px;
      font-weight: 800;
      line-height: 110%;
    }
    &__textarea {
      display: flex;
      padding: 0px 80px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;

      @media (max-width: 1200px) {
        padding: 0;
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      }
      & > div .primaryHeading {
        color: #00b0f0;
        font-family: 'Inter', sans-serif;

        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 30.8px */
      }
      & > div > div {
        color: #fff;
        font-size: 21px;
        font-weight: 400;
        line-height: 110%; /* 22px */
        align-self: stretch;
        margin-bottom: 5px;
      }
      & .cookie-content > div {
        margin-bottom: 10px;
        color: #fff;
        font-family: 'Inter', sans-serif;

        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media (max-width: 900px) {
  .cookies {
    * {
      display: none;
    }
  }

  .cookiesMob {
    display: flex;
    width: 100%;
    padding: 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    // border: 1px solid white;
    &__heading {
      @include w-24-800-110Mob;
    }
    &__textarea {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
      }
      & > div .primaryHeading {
        color: #00b0f0;
        font-family: 'Inter', sans-serif;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 17.6px */
      }
      & > div > div {
        color: #fff;
        font-family: 'Inter', sans-serif;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      & .cookie-content > div {
        margin-bottom: 5px;
        color: #fff;
        font-family: 'Inter', sans-serif;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
