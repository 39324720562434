.billing {
  width: 100%;
  flex: 1;
  position: relative;
  margin-bottom: 2rem;
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: #f2f1f1;

    &__header {
      @include mainHeading;
    }
    &__icons {
      display: flex;
    }
    &__icons > img {
      margin-left: 24px;
    }
    &__icons > button {
      margin-left: 8px;
    }
  }

  &__filters {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond(phone) {
      flex-flow: column;
      padding-bottom: 3rem;
    }

    &__inputContainer {
      display: flex;
      gap: 1.5rem;
      margin: 2rem 0 1.5rem 0;

      @include respond(tab-land) {
        gap: 0.5rem !important;
      }

      @include respond(phone) {
        flex-flow: column;
        margin: 1rem 0 0 0;
      }
      &__input {
        width: 18rem;
        @include respond(tab-land) {
          width: 15rem !important;
        }
      }
    }
    &__button {
      position: relative;
      top: 20px;
      width: 205px;
    }
  }
}

.planningTableActions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
}
