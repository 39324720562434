.BlogsContainer {
  padding: 80px;
  color: #fff;

  &__heading {
    font-size: 72px;
    font-weight: 700;
    text-align: center;
  }

  &__SubHeading {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
}

.BlogsCard {
  background: linear-gradient(to bottom right,
      rgba(21, 191, 253, 1) 5%,
      rgba(156, 55, 253, 1));
  border-radius: 20px;
  padding: 2px;
  overflow: hidden;
  color: #fff;

  &__inner {
    background-color: #0a0e25;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 20;
    padding: 20px;
  }

  &__image {
    height: 350px;
    display: flex;
    justify-items: center;
    align-items: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
  }

  &__desc {
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  &__link {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    color: #00b0f0;
  }
}

.BlogsShow {
  padding: 80px;

  &__img {
    display: flex;
    justify-content: center;

    img {
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
  }
}

.BlogsShowContainer {
  padding: 80px;
  color: #fff;

  &__heading {
    font-size: 45px;
    font-weight: 700;
  }

  &__writter {
    font-size: 18px;
    font-weight: 700;
  }

  &__dayTime {
    font-size: 18px;
    font-weight: 400;
  }
}

.BlogsCreateContainer {
  color: #373737;

  &__heading {
    font-size: 45px;
    font-weight: 700;
  }

  &__fields {
    label {
      font-size: 24px;
      font-weight: 600;
    }

    input,
    select {
      margin-top: 10px;
      border: 1px solid #737794;
      background-color: transparent;
      border-radius: 20px;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    select option {
      background-color: #00b0f0;
    }
  }

  &__imageSection {
    border: 1px dashed #373737;
    min-height: 250px;
    background: rgb(224 224 224 / 52%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__button {
      border: 2px solid #00b0f0;
      padding: 12px 16px;
      color: #00b0f0;
      border-radius: 50px;
      text-align: center;
      cursor: pointer;
    }
  }

  &__submitBtn button {
    background: #00b0f0;
    padding: 12px 25px;
    color: #0a0d23;
    border-radius: 50px;
  }
}

.textEditorBtns p {
  background-color: #00b0f0;
  color: #fff;
  border-radius: 10px;
  padding: 3px 8px;
  cursor: pointer;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  color: #373737 !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border: none !important;
}

.ql-snow .ql-stroke {
  stroke: #373737 !important;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #373737 !important;
}

.QuillEditor .ql-editor {
  min-height: 250px;
}

.ql-toolbar.ql-snow {
  border-radius: 20px 20px 0 0;
  border: 1px solid #737794 !important;
}

.ql-container.ql-snow {
  border-radius: 0 0 20px 20px;
  border: 1px solid #737794 !important;
  border-top: none !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background-color: #00b0f0 !important;
  border: none !important;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.adminAllBlogs {
  .BlogsCard {
    background: #fff !important;
    border: 1px solid #0D4EA1;
    padding: 20px;
    color: #000;
  }

  .fa-pencil {
    color: #0D4EA1;
    font-size: 20px;
    cursor: pointer;
  }

  .fa-trash {
    color: #ca1919;
    font-size: 20px;
    cursor: pointer;
  }
}