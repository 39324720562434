@media (min-width: 900px) {
  .qaLayoutMob {
    * {
      display: none;
    }
  }
  .active-tabMob {
    * {
      display: none;
    }
  }

  .qaLayout {
    display: flex;
    padding: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    &__heading {
      color: #fff;
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 72px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%; /* 61.6px */
    }
    &__tabs {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 12px;
      &__tab {
        display: flex;
        padding: 6px 8px;
        // justify-content: center;
        // align-items: center;
        gap: 8px;
        color: #737794;
        font-family: 'Inter', sans-serif;
        text-align: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 0.5px;
        transition: 'color 0.3s ease';
        &:hover {
          cursor: pointer;
          border-bottom: 2px solid #00b0f0;
          color: #00b0f0;
          font-family: 'Inter', sans-serif;

          @media (min-width: 900px) {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: 0.5px;
          }
        }
        & > div > span {
          font-family: Times New Roman;
          font-size: 20px;
          // white-space: 'nowrap',
        }
      }
    }
  }
  .active-tab {
    border-bottom: 2px solid #00b0f0;
    .qaLayout__tabs__tab {
      color: #00b0f0;
      font-family: 'Inter', sans-serif;

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }
}
@media (max-width: 900px) {
  .qaLayout {
    * {
      display: none;
    }
  }
  .active-tab {
    * {
      display: none;
    }
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
  }

  .qaLayoutMob {
    display: flex;
    width: 100%;
    padding: 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    &__heading {
      @include w-24-800-110Mob;
    }
    &__tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;
      align-self: stretch;
      &__tab {
        padding: 6px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: #737794;
        font-family: 'Inter', sans-serif;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 0.5px;
        transition: 'color 0.3s ease';
        &:hover {
          cursor: pointer;
          border-bottom: 2px solid #00b0f0;
          color: #00b0f0;
          font-family: 'Inter', sans-serif;
          @media (min-width: 900px) {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: 0.5px;
          }
        }
        & > div > span {
          font-family: Times New Roman;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.5px;
          // white-space: 'nowrap',
        }
      }
    }
  }
  .active-tabMob {
    border-bottom: 2px solid #00b0f0;
    .qaLayoutMob__tabs__tab {
      color: #00b0f0;
      font-family: 'Inter', sans-serif;

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }
}
