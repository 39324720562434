@media (min-width: 900px) {
  .mobnavbar {
    * {
      display: none;
    }
  }

  .sidebarHome {
    * {
      display: none;
    }
  }

  .navbar {
    position: fixed;
    display: flex;
    padding: 24px 80px;
    width: 100%;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: rgba(10, 13, 35, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    @media (max-width: 1350px) {
      padding: 24px 30px;
    }
  }

  .contentContainer {
    position: relative;
    top: 100px;
  }

  .navBarFlags img {
    height: 25px;
    border-radius: 5px;
    left: auto;
  }
}

@media (max-width: 900px) {
  .navbar {
    * {
      display: none;
    }
  }

  .mobnavbar {
    display: flex;
    width: 100%;
    height: 72px;
    padding: 16px;
    justify-content: space-between;

    // align-items: center;
    // background-color: pink;
    &__right {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__left {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}

img {
  cursor: pointer;
}

.pagePath {
  // background: linear-gradient(0deg, $PRIMARY_COLOR 0%, $SECONDARY_COLOR 100%);
  padding: 0.5rem 1rem;
  color: #0f73cc;
  border-radius: 1rem;
  display: flex;
  font-size: 0.8rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;

  @include respond(phone) {
    padding: 0 0.75rem;
    font-size: 0.6rem;
    height: 1.5rem;
  }
}