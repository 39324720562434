.prices {
  &__headerContainer {
    width: 100%;
    // height: 150px;
    // border: 1px solid black;
    position: relative;
    display: flex;
    flex-direction: column;

    &__button {
      width: 100%;
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__plans {
    width: 100%;
    // height: 973px;
    // border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
    padding: 0 0 1.5rem 0;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin-top: 24px;
    &__plans {
      padding-top: 32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
      &__buttons {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
      }
      &__buttons * {
        width: 161.5px;
      }
    }
    &__footer {
      width: 100%;
      // height: 166px;
      // border: 1px solid black;

      &__button {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        gap: 0.2rem;
      }
      &__button * {
        width: 161.5px;
      }
    }
  }
}
