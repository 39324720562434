// Item Types
.danger-item {
  background-color: $DANGER;
  color: #fff;
}

.danger-border-item {
  background-color: transparent;
  color: $DANGER;
  border: 1px solid $DANGER !important;

  &:hover {
    background-color: $DANGER;
    color: #fff;
  }
}

.primary-item {
  background-color: $THEME_COLOR;
  color: #fff;
}

// Border
.no-border {
  border: none;
  outline: none;
}

.border-danger {
  border: 1px solid $DANGER;
}

// Background
.background-transparent {
  background-color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

// CONTAINERS
.shadow-container {
  box-shadow: 0px 4px 40px 0px #0000001a;
}

.circlesGrad {
  width: 300px;
  height: 300px;
  border-radius: 50%; /* Set border-radius to 50% to make it a circle */
  opacity: 0.8;

  background: var(
    --primary-color,
    linear-gradient(180deg, #090221 0%, #0f76e3 100%)
  );
  filter: blur(175px);
  z-index: 1;

  @media (max-width: 900px) {
    width: 250px;
    height: 250px;
  }
}

.mobile-screen {
  display: none;
  @media (max-width: 900px) {
    display: inline-block;
  }
}

.container {
  position: absolute;
  top: 40%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  position: absolute;
  background-color: transparent;
  width: calc(var(--i) * 2.5vmin);
  aspect-ratio: 1;
  border-radius: 50%;
  border: 0.9vmin solid rgb(0, 200, 255);
  transform-style: preserve-3d;
  transform: rotateX(70deg) translateZ(50px);
  animation: my-move 3s ease-in-out calc(var(--i) * 0.08s) infinite;
  box-shadow: 0px 0px 15px rgb(124, 124, 124),
    inset 0px 0px 15px rgb(124, 124, 124);
}

@keyframes my-move {
  0%,
  100% {
    transform: rotateX(70deg) translateZ(50px) translateY(0px);
    filter: hue-rotate(0deg);
  }

  50% {
    transform: rotateX(70deg) translateZ(50px) translateY(-50vmin);
    filter: hue-rotate(180deg);
  }
}
