.dashboard {
  width: 100%;
  flex: 1;
  position: relative;

  &__header {
    @include mainHeading;
  }

  &__filters {
    width: 100%;
    display: flex;
    gap: 1.5rem;
    margin: 2rem 0 1.5rem 0;

    @include respond(tab-port) {
      gap: 0.75rem;
    }
    @include respond(phone) {
      gap: 0.25rem;
    }

    &__input {
      width: 18rem;

      @include respond(phone) {
        width: 50%;
      }
    }
  }
}

.planningTableActions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
}
