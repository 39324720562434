@media (min-width: 900px) {
  .homeMob {
    * {
      display: none;
    }
  }

  .boostHorizonMob {
    * {
      display: none;
    }
  }

  .sleevesMob {
    * {
      display: none;
    }
  }

  .home {
    display: flex;
    padding: 80px;
    justify-content: center;
    align-items: center;
    gap: 80px;

    @media (max-width: 1200px) {
      flex-direction: column-reverse;
    }

    &__left {
      width: 50%;
      height: 532.523px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    &__right {
      flex: 1;
      display: flex;
      width: 661px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      flex-shrink: 0;

      @media (max-width: 1200px) {
        width: 100%;
      }

      &__heading {
        color: #fff;
        font-family: 'Inter', sans-serif;

        font-size: 56px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%;
        text-align: center;

        // @media (max-width: 1200px) {
        //   text-align: center;
        // }
        /* 61.6px */
      }

      &__content {
        color: #fff;
        font-family: 'Inter', sans-serif;

        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        /* 28px */
        &>span {
          font-family: 'Times New Roman';
        }
      }
    }
  }



  .boostHorizon {
    height: 1115px;
    display: flex;
    padding: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;

    &__heading {
      // align-self: stretch;

      color: #fff;
      text-align: center;
      font-family: 'Inter', sans-serif;

      font-size: 56px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%;
      /* 61.6px */
    }

    &__image {
      display: flex;
      align-items: center;
      // width: 1280px;
      height: 607px;

      @media (max-width: 1200px) {
        width: 900px;
        flex-direction: column-reverse;
        height: auto;
      }

      &__image {
        width: 45%;

        @media (max-width: 1200px) {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1rem;
        }
      }

      &__textarea {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;

        @media (max-width: 1200px) {
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
        }

        //
        &>div {
          display: flex;
          padding: 8px 0px 8px 48px;
          border-left: 3px solid;
          border-image: linear-gradient(to bottom,
              rgba(147, 55, 251, 1),
              rgba(140, 224, 255, 1) 20%,
              rgba(0, 0, 0, 0) 90%);
          border-image-slice: 1;

          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;

          @media (max-width: 1200px) {
            width: 45%;
          }
        }

        &>div .primaryHeading {
          color: #00b0f0;
          font-family: 'Inter', sans-serif;

          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 110%;

          /* 30.8px */
        }

        &>div>div {
          width: 590px;
          color: #fff;
          font-family: 'Inter', sans-serif;

          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          /* 22px */
          align-self: stretch;

          @media (max-width: 1200px) {
            width: 100%;
            flex-direction: column-reverse;
            height: auto;
          }
        }
      }
    }
  }



  .sleeves {
    display: flex;
    padding: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    align-self: stretch;
    // height: 862px;

    @media (max-width: 1300px) {
      height: auto;
    }

    &__heading {
      color: #fff;
      text-align: center;
      font-family: 'Inter', sans-serif;

      font-size: 56px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%;
      /* 61.6px */
      max-width: 779px;
    }

    &__container {
      // height: 498px;
      max-width: 1440px;
      width: 100%;
      // max-width: 1280px;
      // width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &>div {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1 0 0;

        &>div {
          color: #fff;
          font-family: 'Inter', sans-serif;

          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;

          /* 38.4px */
          &>span {
            font-family: 'Times New Roman';
            font-size: 18px;
          }
        }
      }

      &__content {
        &--icon {
          span {
            font-size: 24px !important;
            line-height: 38.4px !important;
          }
        }
      }

      &__content {
        width: 50%;
      }

      &__image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .desktopFont {
    color: #fff;
    text-align: center;
    font-family: 'Inter', sans-serif;

    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    /* 36px */
  }
}

@media (max-width: 900px) {
  .home {
    * {
      display: none;
    }
  }

  .boostHorizon {
    * {
      display: none;
    }
  }

  .sleeves {
    * {
      display: none;
    }
  }

  .homeMob {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;

    &__regainContainer {
      display: flex;
      padding: 0px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      align-self: stretch;

      &__contentContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;

        &__heading {
          width: 309px;
          @include w-24-800-110Mob;
        }

        &__content {
          @include w-14-400Mob;
        }
      }
    }
  }

  // some side styling for mobile and laptop plani link logo

  .mobFont {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  // ----------ends here-------------//

  .boostHorizonMob {
    margin-top: 32px;
    display: flex;
    height: 773px;
    padding: 0px 16px 36px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    &__heading {
      // width: 398px;
      @include w-24-800-110Mob;
    }

    &__textarea {
      display: flex;
      height: 371px;
      align-items: flex-start;
      gap: 12px;
      flex-shrink: 0;
      align-self: stretch;

      &__right {
        width: 193px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;

        @media (max-width: 400px) {
          width: 170px;
          gap: 12px;
        }

        &__above {
          display: flex;
          padding-left: 10px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;
          border-left: 3px solid;
          border-image: linear-gradient(to bottom,
              rgba(147, 55, 251, 1),
              rgba(140, 224, 255, 1) 20%,
              rgba(0, 0, 0, 0) 90%);
          border-image-slice: 1;

          &__heading {
            // width: 183px;
            @include p-14-500-110Mob;
          }

          &__content {
            // width: 183px;
            @include w-12-400-160Mob;
          }
        }

        &__below {
          display: flex;
          padding-left: 10px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;
          border-left: 3px solid;
          border-image: linear-gradient(to bottom,
              rgba(147, 55, 251, 1),
              rgba(140, 224, 255, 1) 20%,
              rgba(0, 0, 0, 0) 90%);
          border-image-slice: 1;

          &__heading {
            // width: 183px;
            @include p-14-500-110Mob;
          }

          &__content {
            // width: 183px;
            @include w-12-400-160Mob;
          }
        }
      }

      &__left {
        width: 193px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;

        @media (max-width: 400px) {
          width: 170px;
          gap: 12px;
        }

        &__above {
          display: flex;
          padding-left: 10px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;
          border-left: 3px solid;
          border-image: linear-gradient(to bottom,
              rgba(147, 55, 251, 1),
              rgba(140, 224, 255, 1) 20%,
              rgba(0, 0, 0, 0) 90%);
          border-image-slice: 1;

          &__heading {
            // width: 183px;
            @include p-14-500-110Mob;
          }

          &__content {
            // width: 183px;
            @include w-12-400-160Mob;
          }
        }

        &__below {
          display: flex;
          padding-left: 10px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;
          border-left: 3px solid;
          border-image: linear-gradient(to bottom,
              rgba(147, 55, 251, 1),
              rgba(140, 224, 255, 1) 20%,
              rgba(0, 0, 0, 0) 90%);
          border-image-slice: 1;

          &__heading {
            // width: 183px;
            @include p-14-500-110Mob;
          }

          &__content {
            // width: 183px;
            @include w-12-400-160Mob;
          }
        }
      }
    }

    &__image {
      width: 348.757px;
      height: 244px;
      flex-shrink: 0;
    }
  }

  .cardsMob {
    display: flex;
    padding: 0px 16px 36px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    &__heading {
      margin-top: 32px;
      @include w-24-800-110Mob;
      justify-content: center;
      align-items: center;
    }

    &__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      align-self: stretch;
    }
  }

  .sleevesMob {
    display: flex;
    padding: 16px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    height: auto;

    &__heading {
      @include w-24-800-110Mob;
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;

      &>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;

        &>div {
          @include w-14-400-160Mob;

          &>span {
            font-family: 'Times New Roman';
            font-size: 14px;
          }
        }
      }
    }
  }
}

.TreatmentPlans {
  background-color: #0A0E25;
}

.TreatmentPlansBG {
  background-image: url("../../assets/TreatmentPlanBG.png");
  position: sticky;
  top: 0;
  z-index: 2;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.TreatmentPlansContainer {
  padding: 80px;
  width: 100%;

  &__heading {
    font-size: 56px;
    font-weight: 700;
    color: #fff;
  }

  &__subHeading {
    font-size: 24px;
    font-weight: 500;
    color: #00B0F0;
  }

  &__card {
    margin-top: 20px;

    &__heading {
      font-size: 28px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      height: 100px;
    }

    &__review {
      font-size: 20px;
      font-weight: 500;
      color: #fff;
    }
  }
}

.home-banner-SubHeading {
  font-size: 20px;
  color: #fff;

  &__home-primary {
    color: #00b0f0;
    font-size: 20px;
    font-weight: 400;
  }
}

.cards {
  padding: 80px;

  &__heading {
    margin-bottom: 80px;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    text-align: center;
  }

}

.makeItPossible {
  padding: 80px;

  &__heading {
    color: #fff;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    margin-bottom: 80px;
  }

  &__card {
    background: linear-gradient(to bottom right,
        rgba(21, 191, 253, 1) 5%,
        rgba(156, 55, 253, 1));
    border-radius: 20px;
    padding: 2px;
    overflow: hidden;
    color: #fff;
    height: 100%;

    &__inner {
      background-color: #0A0E25;
      width: 100%;
      height: 100%;
      padding: 30px;
      border-radius: 20px;
      z-index: 20;
    }

    &__heading {
      font-size: 28px;
      font-weight: 600;
    }

    &__desc {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__container {

    &__pos1 {
      position: absolute;
      top: 18%;
      left: 20%;
      transform: translate(-50%, -50%);
    }

    &__pos3 {
      position: absolute;
      top: 56%;
      left: 20%;
      transform: translate(-50%, -50%);
    }

    &__pos5 {
      position: absolute;
      top: 93%;
      left: 20%;
      transform: translate(-50%, -50%);
    }

    &__pos2 {
      position: absolute;
      top: 37%;
      left: 80%;
      transform: translate(-50%, -50%);
    }

    &__pos4 {
      position: absolute;
      top: 75%;
      left: 80%;
      transform: translate(-50%, -50%);
    }
  }
}

.deleteImgBtn {
  text-align: center;
  color: red;
  margin-top: 10px;
  padding: 5px 15px;
  border: 1px solid red;
  border-radius: 20px;
  cursor: pointer;
}