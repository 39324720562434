.planCardContainer {
  background: linear-gradient(to bottom right,
      rgba(21, 191, 253, 1) 5%,
      rgba(156, 55, 253, 1));
  border-radius: 20px;
  padding: 2px;
  overflow: hidden;
  height: 100%;

  &__inner {
    background-color: #0A0E25;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 20;
  }
}

@media (min-width: 900px) {
  .planCardHomeMob {
    * {
      display: none;
    }
  }

  .lineMob {
    * {
      display: none;
    }
  }

  .planCardHome {
    display: flex;
    position: relative;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 20px;
    box-shadow: 0px 12px 56px 0px rgba(6, 28, 61, 0.12);
    z-index: 20;

    &__heading {
      color: #fff;
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      /* 150% */
      letter-spacing: -0.64px;
    }

    &__features {
      display: flex;
      flex-direction: column;
      width: 100%;
      // justify-content: center;
      align-items: flex-start;
      gap: 20px;

      &__feature {
        display: flex;
        // align-items: center;
        gap: 0.75rem;
        color: #fff;

        /* Body/Medium/400 */
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        /* 150% */
        img {
          width: 1.75rem;
          height: 1.75rem;
        }
      }
    }

    &__additionalDets {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      &>h5 {
        color: #00b0f0;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
      }

      &>p {
        color: #737794;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      &__price {
        &--price {
          color: #fff;
          font-weight: 600;
          font-size: 36px;
        }

        &--euro {
          color: #fff;
          font-weight: 600;
          font-size: 36px;
        }
      }

      &__dis {
        color: #fff;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 150% */
      }
    }
  }

  .line {
    width: 360px;
    height: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

@media (max-width: 900px) {
  .planCardHome {
    * {
      display: none;
    }
  }

  .line {
    * {
      display: none;
    }
  }

  .planCardHomeMob {
    display: flex;
    padding: 12px 16px;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    align-self: stretch;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.07);
    box-shadow: 0px 12px 56px 0px rgba(6, 28, 61, 0.12);

    &__heading {
      @include w-24-700-200Mob;
      font-size: 30px;
    }

    &__features {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      &__feature {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        @include w-14-400-171Mob;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__additionalDets {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      &>h5 {
        @include p-16-600Mob;
      }

      &>p {
        @include g-12-400-200Mob;
      }

      &__dis {
        @include w-14-600-171Mob;
      }

      &__price {
        &--price {
          @include w-24-700-200Mob;
          font-size: 36px;
          color: #fff;
        }

        &--euro {
          @include w-24-700-200Mob;
          font-size: 36px;
          color: #fff;
        }
      }
    }
  }

  .line {
    width: 360px;
    height: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}