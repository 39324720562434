.patientPlans {
  width: 100%;
  flex: 1;
  position: relative;

  &__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    gap: 1rem;

    @include respond(phone) {
      padding: 0;
      // align-items: flex-start;
    }

    &__right {
      @media screen and (max-width: 800px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    &__avatar {
      width: 7rem;
      height: 6rem;
      border-radius: 50%;
      overflow: hidden;

      @include respond(tab-port) {
        width: 6rem;
        height: 5rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__summaries {
      display: flex;
      // max-width: 40rem;

      flex-grow: 1;
      gap: 1rem;

      @include respond(tab-port) {
        gap: 1.5rem;
      }
      @include respond(tab-port) {
        gap: 1rem;
      }

      @include respond(phone) {
        width: 100%;
        gap: 0;
      }
    }

    &__newPlan {
      display: flex;
      gap: 0.5rem;
      @include respond(phone) {
        gap: 1.5rem;
        position: absolute;
        bottom: -2.5rem;
        left: 50%;
        display: flex;
        justify-content: center;
        width: 100%;
        transform: translateX(-50%);
      }
    }
    &__summary {
      display: flex;
      flex-flow: column;
      width: 50%;
      &__item {
        font-size: 1rem;
        font-weight: 400;
        display: flex;
        align-items: center;

        @include respond(tab-port) {
          height: 3rem;
          font-size: 0.75rem;
        }
        @include respond(phone) {
          flex-flow: column;
          align-items: flex-start;
        }
        &--status {
          @include respond(tab-port) {
            font-size: 0.75rem !important;
          }
          @include respond(phone) {
            margin-top: 0.5rem;
          }
        }

        label {
          color: $TEXT_GRAYISH_COLOR !important;
          @include respond(phone) {
            font-size: 0.8rem;
          }
        }
        span {
          font-size: 1.125rem;
          font-weight: 500;
          margin-left: 0.75rem;
          color: $TEXT_DEFAULT_COLOR;

          @include respond(tab-port) {
            font-size: 0.8rem;
          }
          @include respond(phone) {
            margin-left: 0;
          }
        }
        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: 0.75rem;
          cursor: pointer;
          @include respond(phone) {
            width: 0.75rem;
            height: 0.75rem;
          }
        }
      }
    }
  }

  &__header {
    display: flex;
    gap: 1rem;
    @include respond(phone) {
      gap: 0;
    }

    img {
      @include respond(phone) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.patientImage {
  &__overlay {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear;

    i {
      cursor: pointer;
    }
  }
  &:hover {
    .patientImage__overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}
