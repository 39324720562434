.InvisibleOrthodontics {
    background-color: rgba(10, 16, 42, 0.75);
}

.InvisibleOrthodonticsGB {
    position: sticky;
    top: 0;
    z-index: 2;
    background-image: url('../../assets/Invisible_Orthodontics_BG.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.InvisibleOrthodonticsContainer {
    position: relative;
    padding: 80px;

    &__heading {
        font-size: 56px;
        font-weight: 700;
        font-family: 'Times New Roman', Times, serif;
        color: #fff;
        text-align: center;

        &__redText {
            color: #FF0000;
        }
    }
}

.OrthodonticsCardContainer {
    background: linear-gradient(to bottom right,
            rgba(21, 191, 253, 1) 5%,
            rgba(156, 55, 253, 1));
    border-radius: 20px;
    padding: 2px;
    overflow: hidden;
    height: 100%;

    &__inner {
        background-color: #0A0E25;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        z-index: 20;
    }
}

.OrthodonticsCardHome {
    position: relative;
    padding: 32px;
    border-radius: 20px;
    box-shadow: 0px 12px 56px 0px rgba(6, 28, 61, 0.12);
    color: #fff;

    &__heading {
        font-size: 32px;
        font-weight: 600;
    }

    &desc {
        font-size: 20px;
        font-weight: 500;
    }

    &__img {
        height: 52px;
        width: 53px;
    }
}

.card-container {
    position: relative;
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    z-index: 1;
}