.planCard {
  max-width: 340px;
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;

  h2 {
    font-size: 1.75rem;
    letter-spacing: -2%;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
  }

  &__features {
    width: 100%;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding: 1.5rem 0;
    margin: 1.5rem 0;
    color: $TEXT_DEFAULT_COLOR;
    display: flex;
    flex-flow: column;
    gap: 0.75rem;

    &__feature {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      img {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }

  &__additionalDets {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.75rem;

    p {
      font-size: 0.85rem;
    }
    span {
      font-weight: 600;
    }
  }

  &__price {
    text-align: center;
    margin-top: 1.5rem;
  }
}
