@media (min-width: 900px) {
  .mobfooter {
    * {
      display: none;
    }
  }

  .footer {
    width: 100%;
    margin-top: 100px;
    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(6px);

    // background-color: aliceblue;
    &__subscribeArea {
      padding-top: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      gap: 36px;

      &__above {
        padding-top: 32px;
        width: 100%;
        display: flex;
        padding: 0px 80px;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
      }

      &__below {
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        display: flex;
        padding: 24px 80px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        @media (max-width: 1200px) {
          flex-direction: column;
          gap: 12px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .footer {
    * {
      display: none;
    }
  }

  .mobfooter {
    display: flex;
    width: 100%;
    padding: 0 22px;
    margin-top: 20px;
    padding-top: 24px;
    flex-direction: column;
    gap: 20px;
    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(6px);

    &__end {
      display: flex;
      padding-top: 12px;
      padding-bottom: 12px;
      flex-direction: column;
      gap: 8px;

      &__content {
        @include g-12-400-110Mob;
        // min-width: 400px;
      }
    }
  }
}