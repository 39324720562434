@media (min-width: 900px) {
  .downloadMob {
    * {
      display: none;
    }
  }
  .download {
    display: flex;
    padding: 80px 80px 120px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;

    &__container {
      display: flex;
      max-width: 1048px;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 36px;
      &__heading {
        width: 921px;
        color: #fff;
        text-align: center;
        font-size: 72px;
        font-weight: 800;
        line-height: 110%;
      }
      &__note {
        color: #fff;
        text-align: center;
        font-family: 'Inter', sans-serif;

        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 22px */
      }
    }
    &__container1 {
      width: 692px;
      height: 418px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 80px;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        & > div {
          color: #fff;
          text-align: center;
          font-family: 'Inter', sans-serif;

          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 17.6px */
        }
        & > .downloadBtn {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          cursor: pointer;
          gap: 6px;
          & > div {
            color: #fff;
            font-family: 'Inter', sans-serif;

            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px; /* 150% */
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .download {
    * {
      display: none;
    }
  }
  .downloadMob {
    display: flex;
    padding: 0px 16px;
    padding-bottom: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      &__heading {
        @include w-24-800-110Mob;
      }
      &__note {
        color: #fff;
        text-align: center;
        font-family: 'Inter', sans-serif;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 15.4px */
      }
    }
    &__container1 {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 1rem;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        width: 50%;

        img {
          max-width: 150px;
          width: 100%;
        }

        & > div {
          color: #fff;
          text-align: center;
          font-family: 'Inter', sans-serif;

          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 12.1px */
          // width: 189px;
        }
        & > .downloadBtn {
          width: 88px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;
          cursor: pointer;
          & > div {
            color: #fff;
            font-family: 'Inter', sans-serif;

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}
