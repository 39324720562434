@media (min-width: 900px) {
  .legalWritingMob {
    * {
      display: none;
    }
  }

  .legalWriting {
    display: flex;
    padding: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    // border: 1px solid white;
    &__heading {
      color: #fff;
      text-align: center;
      font-size: 56px;
      font-weight: 800;
      line-height: 110%;
    }
    &__textarea {
      display: flex;
      padding: 0px 80px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      // border: 1px solid white;

      @media (max-width: 1200px) {
        padding: 0;
      }
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      }
      & > div .primaryHeading {
        color: #00b0f0;
        font-family: 'Inter', sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 30.8px */
      }
      & > div .whiteHeading {
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 26.4px */
      }
      & > div > div {
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 21.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 22px */
        align-self: stretch;
      }
    }
  }
}

@media (max-width: 900px) {
  .legalWriting {
    * {
      display: none;
    }
  }

  .legalWritingMob {
    display: flex;
    width: 100%;
    padding: 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    // border: 1px solid white;
    &__heading {
      @include w-24-800-110Mob;
    }
    &__textarea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      // border: 1px solid white;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
      }
      & > div .primaryHeading {
        color: #00b0f0;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 17.6px */
      }
      & > div .whiteHeading {
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 17.6px */
      }
      & > div > div {
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
      }
    }
  }
}
