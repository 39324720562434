// Mobile and Tablet Screens
@media (width < 900px) {

  // Plannig to Choose
  .cards {
    padding: 30px 22px;
    gap: 30px;

    &__heading {
      margin-bottom: 20px;
      font-size: 28px !important;
      font-weight: 700;
      width: 100%;
    }
  }

  // Treatment Plans
  .TreatmentPlansBG {
    background: #191c31;
    background-size: cover;
  }

  .TreatmentPlansContainer {
    padding: 25px 16px;

    &__heading {
      font-size: 30px;
      margin-bottom: 20px;
    }

    &__subHeading {
      font-size: 18px;
      font-weight: 500;
      color: #00b0f0;
    }

    &__card {
      &__heading {
        font-size: 20px;
        margin-bottom: 15px;
        height: auto;
      }
    }
  }

  // Make it Possible
  .makeItPossible {
    padding: 25px 22px;

    &__heading {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    &__card {
      &__inner {
        padding: 15px;
      }

      &__heading {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0.25rem;
      }

      &__desc {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  // Orthodontic
  .InvisibleOrthodonticsGB {
    background-size: cover;
  }

  .InvisibleOrthodonticsContainer {
    padding: 25px 22px;

    &__heading {
      font-size: 36px;
      margin-bottom: 25px !important;
    }
  }

  .OrthodonticsCardHome {
    padding: 20px;

    &__heading {
      font-size: 20px;
      font-weight: 600;
    }

    &desc {
      font-size: 14px;
      font-weight: 500;
    }

    &__img {
      height: 42px;
      width: 42px;
    }
  }

  // Exclusive Treatment
  .exclusiveTreatmentContainer {
    padding: 40px;
    display: block;

    &__heading {
      font-size: 25px;
      text-align: center;
      margin-bottom: 40px;
    }

    &__desc {
      font-size: 16px;
      text-align: justify;
      margin-top: 20px;
    }

    &__desc:last-child {
      margin-bottom: 0 !important;
    }
  }

  // Banner
  .home-banner-heading {
    font-size: 25px !important;
    text-align: center;
  }

  .home-banner-SubHeading {
    font-size: 18px !important;
    text-align: center;
  }

  // Blogs
  .BlogsContainer,
  .BlogsShow {
    padding: 30px 16px;

    &__heading {
      font-size: 28px;
    }

    &__SubHeading {
      font-size: 16px;
    }
  }

  .BlogsShow {
    &__img {
      height: auto;
    }
  }

  .BlogsShowContainer {
    padding: 30px 16px;

    &__heading {
      font-size: 24px;
      font-weight: 700;
    }

    &__writter {
      font-size: 14px;
    }

    &__dayTime {
      font-size: 14px;
    }
  }

  .LogoText {
    font-size: 1.5rem;
  }

  .mainLayout__page {
    width: 100% !important;
    left: 0 !important;
    top: 30px !important;
  }

  .BlogsCreateContainer {
    &__fields {

      input,
      select {
        width: 100% !important;
      }
    }
  }
}

@media (width >=1000px) and (width <=1200px) {
  .TreatmentPlansContainer {
    &__card {
      &__heading {
        height: auto;
        margin-bottom: 20px;
      }
    }
  }
}

@media (width <=1200px) {

  // Banner
  .bannerMobileContainer {
    width: 100%;
    height: 100%;
    padding: 25px 16px;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
}

@media (width <=1100px) {

  .exclusiveTreatmentContainerDesktop,
  .desktopBanner {
    display: none !important;
  }

  .exclusiveTreatmentContainerMobile,
  .bannerMobile {
    display: block !important;
  }

  .home-banner-heading {
    font-size: 46px;
    text-align: center;
  }

  .home-banner-SubHeading {
    font-size: px;
    text-align: center;
  }
}

@media (width > 1100px) {
  .exclusiveTreatmentContainerDesktop {
    display: flex !important;
    align-items: start;
  }

  .desktopBanner {
    display: flex;
  }

  .exclusiveTreatmentContainerMobile,
  .bannerMobile {
    display: none !important;
  }
}

// XL Screen
@media (width >=1600px) {

  // Exclusive Treatment
  .exclusiveTreatmentContainer {

    &__heading {
      font-size: 30px;
    }

    &__desc {
      font-size: 27px;
    }
  }

  .home-banner-heading {
    font-size: 80px;
  }

  .BannerVideo {
    max-width: 600px !important;
  }

  .home-banner-SubHeading,
  .home-banner-SubHeading span {
    font-size: 34px !important;
  }
}