.exclusiveTreatmentContainer {
    background-color: rgba(255, 255, 255, 0.07);
    padding: 30px;
    display: flex;
    align-items: flex-start;
    gap: 30px;

    &__heading {
        color: #00B0F0;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 20px
    }

    &__desc {
        color: #fff;
        font-size: 22px;
        margin-bottom: 20px
    }
}

.LogoText {
    font-family: 'Times New Roman', Times, serif;
    font-size: 2rem;

    &__redText {
        color: #FF0000;
    }
}